import '../main';
import { z } from 'zod';
import { _t, clearValidationErrors, displayValidationErrors, t } from '../../i18n';
import { Notifications, goToFormStep, parseDefaultValue, setLoadingState, simulateFormAction } from '../helpers';
import { DATA_DEFAULT_ATTRIBUTE, EMAIL_PREFILL_LOCAL_STORAGE_KEY, i18n_PATH_ATTRIBUTE } from '../../../constants';

const authorizeForm = document.querySelector<HTMLFormElement>('#formAuthorize');

/**
 * Handle the form submission.
 */
authorizeForm?.addEventListener('submit', async e => {
  // Get form data
  e.preventDefault();
  const formData = new FormData(e.target as HTMLFormElement, e.submitter);
  const formDataEntries = Object.fromEntries(formData) as unknown as AuthorizeIn;
  const formRef = e.target as HTMLFormElement;
  // Clear existing validation errors
  clearValidationErrors(formRef);

  // Our form has two steps. We need to know if the user clicked on the "save and continue" button or the "finish" button.
  const steps = Array.from(formRef.querySelectorAll<HTMLDivElement>('[data-step]'));
  const isFirstStep = steps.some(x => x.classList.contains('active') && x.getAttribute('data-step') === '1');

  try {
    if (isFirstStep) {
      const safeParse = await AuthorizeSchema.safeParseAsync(formDataEntries);
      if (safeParse.success) {
        /**
         * We want to check if user exists before we continue to the second step.
         */
        return await handleStepSwitch(formRef, safeParse.data.email, 'input[type="password"]');
      } else {
        const issuePaths = safeParse.error.issues?.flatMap(issue => issue.path);
        const firstStepPaths = ['email'];
        if (!issuePaths.some(path => firstStepPaths.includes(String(path)))) {
          /**
           * If the issues don't include any of the first step fields, we can continue to the second step.
           */
          setLoadingState(formRef, false);
          return await handleStepSwitch(formRef, formDataEntries.email, 'input[type="password"]');
        } else {
          setLoadingState(formRef, false);
          displayValidationErrors(formRef, safeParse.error, firstStepPaths);
        }
      }
    } else {
      setLoadingState(formRef, true);
      const parsed = await AuthorizeSchema.parseAsync(formDataEntries);

      const inputIn = new FormData();
      inputIn.append('username', parsed.email);
      inputIn.append('password', parsed.password);

      if (authorizeForm) {
        simulateFormAction(inputIn, formRef);
      }
    }
  } catch (error) {
    if (error instanceof z.ZodError) {
      displayValidationErrors(formRef, error);
    } else {
      Notifications.createErrorToast(error, 'Authorize page form submit error:');
    }
    setLoadingState(formRef, false);
  }
});

/**
 * Initial page load actions.
 */
document.addEventListener('DOMContentLoaded', () => {
  const emailInput = authorizeForm?.querySelector<HTMLInputElement>('input[name="email"]');

  /**
   * Prefill email input if it's in the local storage or passed to hbs.
   */
  if (emailInput) {
    const emailPrefillValue = localStorage.getItem(EMAIL_PREFILL_LOCAL_STORAGE_KEY);
    if (emailPrefillValue) {
      emailInput.value = emailPrefillValue;
    }

    const emailDefaultValue = parseDefaultValue(emailInput.getAttribute(DATA_DEFAULT_ATTRIBUTE));
    if (emailDefaultValue) {
      emailInput.value = emailDefaultValue;
    }
  }

  const formHasError = authorizeForm?.getAttribute('data-error');
  if (authorizeForm && formHasError === 'errors.invalidPassword') {
    goToFormStep(authorizeForm, 2);

    const relatedFormItem = authorizeForm.querySelector(`[name="password"]`);

    if (relatedFormItem) {
      relatedFormItem.setAttribute('aria-invalid', 'true');
      relatedFormItem.setAttribute('aria-describedby', 'errors.invalidPassword');

      const formGroup = relatedFormItem.closest('div.form-group');

      if (formGroup) {
        formGroup.setAttribute('data-invalid', 'true');

        const errorNode = document.createElement('span');
        errorNode.textContent = t('errors.invalidPassword');
        errorNode.id = 'errors.invalidPassword';
        errorNode.setAttribute(i18n_PATH_ATTRIBUTE, 'errors.invalidPassword');

        errorNode.className = 'field-error';

        formGroup.appendChild(errorNode);
      }
    }
  }
});

/**
 * Validation schema for the authorize form.
 */
const AuthorizeSchema = z.object({
  email: z.string().email({ message: _t('validations.email') }),

  password: z
    .string({ required_error: _t('validations.required') })
    .min(6, { message: _t('validations.minLength') })
    .max(255, { message: _t('validations.maxLength') }),
});

type AuthorizeIn = z.infer<typeof AuthorizeSchema>;

async function handleStepSwitch(form: HTMLFormElement, email: string, focusQuerySelector?: string) {
  try {
    setLoadingState(form, true);

    const userExistsResponse = await fetch(
      process.env.NODE_ENV === 'development'
        ? `https://auth.dev.sparta.cra.zone/v1/users/exists?email=${encodeURIComponent(email)}`
        : `/v1/users/exists?email=${encodeURIComponent(email)}`,
    );

    if (userExistsResponse.status === 404) {
      // Go to register form and save mail to local storage
      localStorage.setItem(EMAIL_PREFILL_LOCAL_STORAGE_KEY, email);

      // return window.location.assign(`/register.html${window.location.search}`); // test
      return window.location.assign(`/v1/register${window.location.search}`);
    } else {
      localStorage.removeItem(EMAIL_PREFILL_LOCAL_STORAGE_KEY);
      return goToFormStep(form, undefined, focusQuerySelector);
    }
  } catch (e) {
    console.error('authorize.handleStepSwitch error:', e);
  } finally {
    setLoadingState(form, false);
  }
}
